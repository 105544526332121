<template>
  <el-form label-width="120px" label-position="top" size="mini">
    <el-collapse>
      <el-collapse-item title="Источник данных">
        <source-data v-model="localValue.sourceData"></source-data>

        <div v-if="localValue.sourceData.entityId">
          <el-checkbox v-model="localValue.sourceData.customSorting">Произвольная сортировка</el-checkbox>
          <el-form-item label="Поле для сортировки внутри столбца">
            <select-field v-model="localValue.sourceData" property="sorting"></select-field>
          </el-form-item>
          <el-form-item label="Сортировать" v-if="localValue.sourceData.sorting">
            <el-radio-group v-model="localValue.sourceData.sortingDirection">
              <el-radio-button label="ASC">По возрастанию</el-radio-button>
              <el-radio-button label="DESC">По убыванию</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </div>

        <el-form-item label="Лимит записей">
          <el-input type="number" size="small" v-model="localValue.sourceData.limit">
          </el-input>
        </el-form-item>

        <editor-filters v-model="localValue.sourceData.filters"></editor-filters>
      </el-collapse-item>

      <el-collapse-item title="Группировка столбцов" v-if="localValue.sourceData.entityId">
        <el-checkbox v-model="localValue.listData.isAdditionalSource">Дополнительный источник <br/>данных</el-checkbox>

        <div v-if="localValue.listData.isAdditionalSource">
          <source-data v-model="localValue.listData"></source-data>
        </div>

        <div v-if="localValue.listData.isAdditionalSource === false">
          <el-form-item :label="$t('interface_editor.component_editor.editor_tasks_source.group.field')">
            <select-field v-model="localValue.sourceData" property="field"></select-field>
          </el-form-item>
        </div>

        <div v-if="localValue.listData.isAdditionalSource && localValue.listData.entityId">
          <el-checkbox v-model="localValue.sourceData.fieldIsLink">
            Поле из основного источника<br> является ссылкой<br> на источник группировки
          </el-checkbox>
          <el-form-item v-if="localValue.sourceData.fieldIsLink" label="Поле из основного источника">
            <select-field v-model="localValue.sourceData" property="field"></select-field>
          </el-form-item>
          <el-form-item v-if="localValue.sourceData.fieldIsLink === false" label="Сопоставление полей">
            <el-button type="text" @click="dialogSettingsVisible = true">{{ $t('main.button.edit') }}</el-button>
            <el-dialog title="Сопоставление столбцов"
                       :modal="false"
                       :visible.sync="dialogSettingsVisible">
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Поле из основного источника">
                    <select-field v-model="localValue.sourceData" property="field"></select-field>
                  </el-form-item>
                </el-col>
                <el-col :span="12" v-if="localValue.sourceData.fieldIsLink === false">
                  <el-form-item label="Поле из источника группировки">
                    <select-field v-model="localValue.listData" property="field"></select-field>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-dialog>
          </el-form-item>
        </div>

        <el-form-item :label="$t('interface_editor.component_editor.editor_tasks_source.additional_fields')">
          <additional-fields v-model="localValue.listData"></additional-fields>
        </el-form-item>

        <el-form-item label="Поле для сортировки">
          <el-checkbox v-if="localValue.listData.isAdditionalSource" v-model="localValue.listData.sortingByAdditionalField">
            Из дополнительных полей
          </el-checkbox>
          <el-input v-if="localValue.listData.sortingByAdditionalField || !localValue.listData.isAdditionalSource" placeholder="Поле строкой" v-model="localValue.listData.sorting"></el-input>
          <select-field v-else-if="this.localValue.listData.isAdditionalSource" v-model="localValue.listData" property="sorting"></select-field>
        </el-form-item>

        <el-form-item label="Сортировать" v-if="localValue.listData.sorting || !localValue.listData.isAdditionalSource">
          <el-radio-group v-model="localValue.listData.sortingDirection">
            <el-radio-button label="ASC">По возрастанию</el-radio-button>
            <el-radio-button label="DESC"> По убыванию</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <editor-filters v-model="localValue.listData.filters"></editor-filters>
      </el-collapse-item>
    </el-collapse>
  </el-form>
</template>

<script>
import AdditionalFields from './AdditionalFields'
import SourceData from '@/components/InterfaceEditor/components/editor/Tasks/SourceData.vue'
import EditorSelect from '@/components/InterfaceEditor/components/editor/editor-select'
import EditorRegistrySelect from '@/components/InterfaceEditor/components/editor/editor-registry-select'
import RegistrySelectTree from '@/components/Common/RegistrySelectTree.vue'
import TreeSelect from '@/components/Common/TreeSelect.vue'
import SelectField from './SelectField'
import EditorNumber from '@/components/InterfaceEditor/components/editor/editor-number'
import FilterBuilder, { EComponentTypes } from '../../utils'
import EditorFilters from '../editor-filters'

export default {
  name: 'editor-tasks-source',

  components: {
    EditorFilters,
    FilterBuilder,
    EditorSelect,
    EditorRegistrySelect,
    RegistrySelectTree,
    TreeSelect,
    SourceData,
    SelectField,
    AdditionalFields,
    EditorNumber
  },

  props: {
    value: {
      type: Object
    }
  },

  watch: {
    'localValue.listData.isAdditionalSource': {
      handler () {
        this.localValue.listData.sorting = null
      }
    },

    'localValue.listData.fieldIsLink': {
      handler () {
        this.localValue.listData.field = null
      }
    },

    'localValue.listData.sortingByAdditionalField': {
      handler () {
        this.localValue.listData.sorting = null
      }
    },

    value: {
      handler (value) {
        this.localValue = value
      },
      deep: true
    },

    localValue: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    }
  },

  data () {
    return {
      dialogSettingsVisible: false,
      localValue: this.updateParams(this.value)
    }
  },

  mounted () {
    this.localValue = this.updateParams(this.value)
  },

  methods: {
    updateParams (source) {
      if (source.sourceData === undefined && source.listData === undefined) {
        return JSON.parse(
          JSON.stringify({
            listData: {
              additionalFields: source.additionalFields,
              isAdditionalSource: false,
              filters: []
            },

            sourceData: {
              entityId: source.entityId,
              type: source.type,
              field: parseInt(source.group_list_field.match(/\d+/)),
              sortingDirection: 'ASC',
              filters: this.$attrs.otherProperties.filters,
              limit: this.$attrs.limit
            }
          })
        )
      }

      return source
    },

    open () {
      this.$alert('This is a message', 'Title', {
        confirmButtonText: 'OK',
        callback: action => {
          this.$message({
            type: 'info',
            message: `action: ${action}`
          })
        }
      })
    },

    async getFields (objectId) {
      if (!objectId) {
        return []
      } else {
        let res = await this.$http
          .get(`${this.$config.api}/objecteditor/entities?parent_id=${objectId}&show_children=true`)
        return res.data.data
      }
    }
  }
}
</script>

<style scoped>

</style>
